import ContactForm from "../../components/Contact/ContactForm"

const formData = {
  address: "Japanska 3a, 11070 Beograd, Republika Srbija",
  email: "office@novoinvestment.rs",
  label: "Za sve informacije ili probleme sa našim proizvodima možete da kontaktirate naš tim za podršku.",
  name: "Ime i prezime",
  emailLabel: "E-mail adresa",
  phone: "Telefon",
  company: "Firma",
  message: "Poruka"
}

const contactData = [
  {
    title: "ADRESA",
    info: ["Japanska 3a,", "11070 Beograd,", "Republika Srbija"],
    img: "location"
  },
  {
    title: "TELEFON",
    info: ["+381 11 400 2979"],
    img: "phone"
  },
  {
    title: "EMAIL",
    info: ["office@novoinvestment.rs"],
    img: "mail"
  },
  {
    title: "PIB",
    info: ["105169644"]
  },
  {
    title: "MB",
    info: ["20330155"]
  },
  {
    title: "RADNO VREME",
    info: ["radnim danima", "od 08:00 do 16:00"],
    img: "clock"
  }
]

const ContactContainer = () => {
  return(
    <>
    <div className="contact-container text-center">
      <h2 className="shadowtitle text-black">KONTAKT</h2>
      <div className="contact-address row mx-auto py-4 bg-white">
        {contactData.map((item, key) => (
          <div className="col-12 col-md-6 col-xl-4 mb-4" key={key}>
            <div className="contact-card text-start">
              <div className="d-flex align-items-center">
                <div className="contact-icon pe-2">
                  <img src={`images/icons/${item.img}.svg`} alt=""></img>
                </div>
                <div className="contact-title ">{item.title}</div>
              </div>
                {item.info.map((item, key) => (
                  <div key={key}>{item}</div>
                ))}
            </div>
        </div>
        ))}
      </div>
    </div>
    <div className="contact-page-container">
        <div className="contact-page-section">
          <div className="row justify-content-between m-0">
            <div className="row m-0 support-container pt-0">
            <div className="row m-0 contact-form-container">
          <ContactForm data={formData}/>
        </div>
          </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ContactContainer